@import 'mixins.scss';

.companyName {
  margin-right: 1.5rem;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #c7c7c7;
}

.menuSimply {
  // min-height: rem(64);
  height: 52px;
  padding: 4px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  background: #242424;
  border-bottom: 1px solid $border;

  &__logo {
    // display: block;
    // // padding-top: rem(15);
    // // padding-bottom: rem(15);
    // margin-right: rem(30);
    // line-height: 1;
    // // height: rem(64);
    // white-space: nowrap;
    align-items: baseline;
    display: flex;
    white-space: nowrap;

    &__letter {
      float: left;

      svg {
        margin-right: rem(6);
        height: rem(30);
        width: rem(30);
        path:nth-child(1) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
        }
        path:nth-child(2) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 2s;
        }
        path:nth-child(3) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 4s;
        }

        @keyframes logoAnimate {
          0% {
            stroke: $primary;
            fill: $primary;
          }
          50% {
            stroke: #1ad697;
            fill: #1ad697;
          }
          100% {
            stroke: $primary;
            fill: $primary;
          }
        }
      }
    }
    &__name {
      font-weight: 700;
      color: $green;
      font-size: rem(18);
      // margin-left: rem(40);
    }
    &__descr {
      // color: $white;
      // font-family: 'Nunito Sans', sans-serif;
      // font-size: 10px;
      // font-style: normal;
      // font-weight: 600;
      // line-height: 14px;
      // text-transform: uppercase;
      // width: max-content;
      // margin: 0 0 0 auto;
      // letter-spacing: 1px;
      // // margin-left: rem(40);
      color: $white;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: normal;
      line-height: 36px;
      letter-spacing: -0.07em;
    }
  }

  // white theme
  &__white {
    background: $white;

    .menuSimply {
      &__logo {
        &__name {
          color: $dark;
        }
      }
    }
  }

  // white theme
  &__gray {
    background: $gray-1;

    .menuSimply {
      &__logo {
        &__name {
          color: $dark;
        }
      }
    }
  }
}

// dark theme
[data-vb-theme='dark'] {
  .menuSimply {
    border-color: $dark-gray-4;
  }
}
