@import 'mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* MENU COMPONENT */
.menu {
  display: flex;
  flex-direction: row;
  background: $white;
  font-size: rem(15);
  border-bottom: 1px solid $border;

  /////////////////////////////////////////////////////////////////////////////////////////
  /* LOGO */
  .logoContainer {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 rem(28);

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: rem(24);
      white-space: nowrap;

      svg {
        margin-right: rem(6);
        height: rem(30);
        width: rem(30);
        path:nth-child(1) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
        }
        path:nth-child(2) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 2s;
        }
        path:nth-child(3) {
          stroke: $primary;
          fill: $primary;
          stroke-width: 1px;
          animation: logoAnimate 6s ease-in-out infinite;
          animation-delay: 4s;
        }

        @keyframes logoAnimate {
          0% {
            stroke: $primary;
            fill: $primary;
          }
          50% {
            stroke: #1ad697;
            fill: #1ad697;
          }
          100% {
            stroke: $primary;
            fill: $primary;
          }
        }
      }
    }
    .name {
      font-size: rem(18);
      font-weight: 700;
      color: $black;
    }
    .descr {
      margin-left: rem(13);
      padding-left: rem(13);
      height: rem(28);
      line-height: rem(28);
      border-left: 1px solid $gray-2;
      font-size: rem(14);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ANTD MENU CUSTOMIZATION */
  .navigation {
    flex: 1 1 0%;
    overflow: hidden;
    // padding-bottom: 1px;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /* ICON */
    .icon {
      color: $gray-5;
      margin-right: rem(10);
      font-size: rem(17);
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: relative;
      top: 2px;

      @at-root {
        :global(.ant-menu-submenu-popup) {
          .icon {
            display: none;
          }
        }
      }
    }

    :global(.ant-menu-submenu-title) {
      color: $text;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      > a {
        color: $text;

        &:hover {
          // color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }

    :global(.ant-menu-horizontal) {
      border-bottom: 0;
      background: transparent;
    }

    :global(.ant-menu-submenu-selected) {
      :global(.ant-menu-submenu-title),
      .icon {
        color: $primary;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $primary;
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
/* GRAY THEME */
.gray {
  background: $gray-1 !important;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* DARK THEME */
.dark {
  color: $dark-gray-1;
  background: #242424 !important;
  // border-bottom: 1px solid $dark-gray-4;
  border: none;

  .logoContainer {
    .name {
      color: $white;
    }
    .descr {
      color: $dark-gray-2;
      border-left: 1px solid $dark-gray-4;
    }
  }

  .navigation {
    .icon {
      color: $dark-gray-2;
    }

    :global(.ant-menu-item-group-title) {
      color: darken($dark-gray-3, 10%) !important;
    }

    :global(.ant-menu-submenu-title) {
      color: $dark-gray-2;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      > a {
        color: white;

        &:hover {
          color: lighten($primary, 10%);

          .icon {
            color: $primary;
          }
        }
      }

      border: none;
      line-height: 42px;
      margin: 0 1rem !important;

      &:hover {
        border: none !important;
      }
    }

    :global(.ant-menu-item-selected) {
      border: none !important;

      > a {
        color: $primary !important;
      }
    }
  }
}
