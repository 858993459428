@import 'mixins.scss';

.dropdown {
  padding-top: rem(10);
  padding-bottom: rem(10);
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  @include transition-slow;
  background-color: $gray-1;

  // &::after {
  //   color: $gray-5;
  //   position: absolute;
  //   top: 47%;
  //   right: 15px;
  //   display: inline-block;
  //   width: 0;
  //   height: 0;
  //   margin-left: 0.255em;
  //   vertical-align: 0.255em;
  //   content: '';
  //   border-top: 0.3em solid;
  //   border-right: 0.3em solid transparent;
  //   border-bottom: 0;
  //   border-left: 0.3em solid transparent;
  //   @include transition-slow;
  // }

  &:hover {
    color: $primary;

    &::after,
    .icon {
      color: $primary;
    }
  }

  &Gray {
    background-color: $white;
  }

  &Dark {
    background-color: $dark-gray-4;
    color: white;

    &:hover {
      color: $white;

      &::after,
      .icon {
        color: $white;
      }
    }
  }
}

.title {
  display: block;
  padding: rem(8) rem(20);
}

.icon {
  font-size: rem(18);
  margin-right: rem(6);
  color: white;
  position: relative;
  bottom: -3px;
  @include transition-slow;
}
