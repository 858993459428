// Import mixins
@import 'mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////

[data-vb-theme='default'] {
  .ant-radio-button-wrapper {
    color: $vb-waave-gray;
    &:hover {
      color: $vb-waave-black;
    }

    &:first-child,
    &:last-child {
      border-radius: 3px;
    }
  }

  .ant-radio-group-solid {
    .ant-radio-button-wrapper {
      &-checked {
        &:not(.ant-radio-button-wrapper-disabled) {
          background: #454545;
          &:hover {
            background: #454545;
          }
        }
      }
    }
  }
}

// DARK THEME STYLES
[data-vb-theme='dark'] {
  .ant-radio-disabled {
    .ant-radio-inner {
      border-color: $dark-gray-4 !important;
    }
  }

  .ant-radio-group-solid {
    .ant-radio {
      &-button {
        &-wrapper {
          color: $text;
          &-checked {
            color: $white;
            &:hover,
            &:active {
              color: $white;
            }
          }
        }
      }
    }
  }
}
